<template></template>

<script>
import appUtils from '../../utils/appUtils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AutoLogin',
  data() {
    return {}
  },
  mounted() {
    const token = this.$route.query.token
    const org_id = this.$route.query.org_id
    if (!token || !org_id) {
      this.handleLoginFail()
      return
    }
    appUtils.setLocalToken(token)
    appUtils.setOrgId(org_id)
    appUtils.setLocalTokenExpiration(new Date().getTime() + 3600 * 1000 * 24)
    this.$store.commit('authenticate/setToken', token)
    this.$store.dispatch('authenticate/setLogoutTimer', 3600 * 1000 * 24)
    this.handleAutoLogin()
  },
  methods: {
    async handleAutoLogin() {
      try {
        const response = await this.$store.dispatch('authenticate/authMe')
        if (!response?.success) {
          this.handleLoginFail()
          return
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Đăng nhập thành công',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router
            .replace({ name: 'product-inventory-manager' })
            .catch(error => {})
        }
      } catch (error) {
        console.log(error)
        this.handleLoginFail()
      }
    },
    handleLoginFail(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message || 'Có lỗi xảy ra khi đăng nhập',
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
      appUtils.removeLocalUser()
      appUtils.removeLocalToken()

      this.$router.push({ path: '/welcome' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>